import React from "react";
import HomeVertical from "./Components/Home/homeVertical";
import nkar from "../assets/img/contactBG.webp"
import Info from "./PropsComponents/info";
import Title from "./PropsComponents/title";

export default function About() {

    const information = [ 
        { img: nkar, title: 'Transfer, Mix, Extract.', desc: "With White Diamond Pro you can transfer single stones or parcels from one inventory lot to another; mix single stones and parcels to create a new inventory lot; also extract a stone from a parcel to create a new single stone inventory lot.", i: 1 }, 
        { img: nkar, title: 'Stone and Jewelry Purchase Distribute. ', desc: "White Diamond Pro always delivers new features such as the Stone Purchase Distribution and Jewelry Purchase Distribution that provide an easy method to allocate and distribute a large collection of purchased stones and jewelry items. White Diamond Pro's intelligent auto-allocation procedure helps you with the price per carat calculations.", i: 2 }, 
        { img: nkar, title: 'Inventory Upload to Polygon, Rapnet, and Idex.', desc: "White Diamond Pro enables you to upload your inventory to Polygon, Rapnet, and Idex. You may also create a list of all the items in your inventory not eligible to upload by Polygon, Rapnet, and Idex standards and allow White Diamond Pro to fix and force the upload of the items not eligible.", i: 3 }, 
    ]

    return (
        <>

            <div className="py-14 sm:py-20  
            ">
            <div className="text-center font-bold text-3xl py-10 sm:py-6">
                About Us
            </div>
            <HomeVertical />
            <div className="bg-slate-200">
            <div className="space-y-10 max-w-screen-xl mx-auto px-4 max-sm:py-10 sm:px-12 sm:py-24"> 
            {
                    information && information.map((item, index) => {
                        if(index % 2 == 0){
                            return  <Info class={'lg:flex lg:flex-row  rounded-2xl overflow-hidden'} img={item.img} title={item.title} desc={item.desc} key={index} />
                        }else{
                            return  <Info class={'lg:flex lg:flex-row-reverse  rounded-2xl overflow-hidden'} img={item.img} title={item.title} desc={item.desc} key={index} />

                        }
                    })
                }
            </div> 

            </div>
            </div>

        </>
    )
}