import React from "react";
import VideoBG from "../../../assets/img/Elementor video placeholder.mp4"


export default function VideoBanner() {
    
    return (
        <>
            <div>
                <section className="  flex flex-col items-center justify-center text-center text-white py-0  overflow-hidden">
                    <div className="video-docker relative  xl:h-screen w-full  ">
                        <video className="w-full   object-contain" src={VideoBG} type="video/mp4" autoPlay muted loop></video>
                    </div>
                 
                </section>
            </div>
        </>
    )

}

