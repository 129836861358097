import React from "react";

export default function BlockTitles(props) {

    return (
        <>
            <h2 className="text-4xl mb-12 font-bold text-center">
                <span className="text-main-text">{props.gray}</span>
                <span className="text-main-blue">{props.blue}</span>
            </h2>
        </>
    )
}