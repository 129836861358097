import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Typography,
    Button,
} from "@material-tailwind/react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";

export default function Info(props) {



    return (
        <>
            <div className="max-w-screen-xl mx-auto max-sm:py-5 space-y-0">
                <Card className={`${props.class}  shadow-2xl max-w-screen-xl mx-auto flex flex-col `} key={props.i}>
                    <CardHeader shadow={false} floated={false} className="w-full lg:w-1/2  shadow-xl overflow-hidden mx-0">
                        <img
                            src={props.img}
                            alt="image"
                            className="w-full h-full object-cover "
                        />
                    </CardHeader>
                    <CardBody className="px-5 py-2 w-full max-lg:p-8 lg:px-4 lg:w-1/2 space-y-4 flex flex-col justify-center items-center">
                        <Typography variant="h6" className="uppercase text-main-text mb-4 font-bold ">StartUps</Typography>
                        <Typography variant="h4" className="mb-2 text-main-text">
                            {props.title}
                        </Typography>
                        <Typography className="font-normal mb-8 text-main-text">
                            {props.desc}
                        </Typography>

                        <Button variant="text" className="flex text-main-text p-3 hover:bg-slate-500 rounded-3xl duration-200 items-center gap-2 ">
                            Learn More
                            <ArrowLongRightIcon strokeWidth={2} className="w-4 h-4" />
                        </Button>

                    </CardBody>
                </Card>
            </div>
        </>
    )
}