import React, { useState } from 'react';
import TabsBG from "../../../assets/img/pic3_3b.jpg";
import TabsBG2 from "../../../assets/img/pic4_2b.jpg";
import TabsBG3 from "../../../assets/img/pic4_3b.jpg";
import TabsBG4 from "../../../assets/img/pic5_1b.jpg";
import TabsBG5 from "../../../assets/img/pic10_1b.jpg";
import TabsBG6 from "../../../assets/img/pic10_2b.jpg";

import {
    Tabs, TabsHeader, TabsBody, Tab, TabPanel,
} from "@material-tailwind/react";


export default function HomeTabs(props) {

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };



    // 
    const data = [
        {
            label: "https://www.notion.so/cdn-cgi/image/format=auto,width=96,quality=100/front-static/pages/home/persona-carousel/icons/engineering-v2.png ",
            value: "html",
            desc: TabsBG,
        },
        {
            label: 'https://www.notion.so/cdn-cgi/image/format=auto,width=96,quality=100/front-static/pages/home/persona-carousel/icons/design-v2.png ',
            value: "react",
            desc: TabsBG2,
        },
        {
            label: 'https://www.notion.so/cdn-cgi/image/format=auto,width=96,quality=100/front-static/pages/home/persona-carousel/icons/product.png',
            value: "vue",
            desc: TabsBG3,
        },
        {
            label: 'https://www.notion.so/cdn-cgi/image/format=auto,width=96,quality=100/front-static/pages/home/persona-carousel/icons/marketing.png',
            value: "angular",
            desc: TabsBG4,
        },
        {
            label: 'https://www.notion.so/cdn-cgi/image/format=auto,width=96,quality=100/front-static/pages/home/persona-carousel/icons/operations.png',
            value: "svelte",
            desc: TabsBG5,
        },
        {
            label: 'https://www.notion.so/cdn-cgi/image/format=auto,width=96,quality=100/front-static/pages/home/persona-carousel/icons/hr-v2.png',
            value: "brivte",
            desc: TabsBG6,
        },
    ];




    return (
        <>

            {/* <div className="Block__Padding">
                <BlockTitles gray={"Forth Block "} blue={"Title."} />
                <div className="max-w-screen-xl mx-auto max-xl:px-6">

                    <Tabs className="space-y-10" selectedIndex={activeTab} onSelect={handleTabClick}>
                        <TabList className={'flex sm:space-x-4 max-sm:flex-wrap border-0 outline outline-offset-2 outline-0 '}>
                            {
                                SliderTabs.map((itm, kkk) => {
                                    return <Tab className={'w-1/3 sm:w-1/6 p-2 outline-0 change_class '} id="ullitab" key={kkk}>
                                        <div className="max-md:hidden bg-[#f6f5f4] py-4 flex justify-center max-md:p-2 items-center  rounded-xl !focus:border-[#f1efee]"><img src={itm.image} alt={itm.alt} /></div>
                                        <div className="md:hidden bg-[#f6f5f4] w-full h-full py-4  flex justify-center max-md:p-2 items-center  rounded-xl !focus:border-[#f1efee]"> Tab {kkk + 1} </div>
                                    </Tab>
                                })
                            }
                        </TabList>
                        <div >
                            {
                                SliderTabPanel.map((item, index) => {
                                    return <TabPanel key={index} value={index}>
                                        <div id={item.ID} className="shadow-xl overflow-hidden  rounded-xl">
                                            <img className="w-full" src={item.image} alt={item.alt} />
                                        </div>
                                    </TabPanel>

                                })
                            }
                        </div>

                    </Tabs>
                </div>
            </div> */}

            <div className='max-w-screen-xl  mx-auto p-2 tabs '>
                <Tabs value="html">
                    <TabsHeader className="rounded-2xl p-3 max-sm:p-4  flex justify-around ul">
                        {data.map(({ label, value }) => (
                            <Tab key={value} value={value} className='max-md:w-full lg:px-5 max-md:p-0  md:w-full py-5 max-sm:w-full sm:w-[80%] '  >
                          
                                <img src={label} alt="" />
                            </Tab>
                        ))}
                    </TabsHeader>
                    <TabsBody>
                        {data.map(({ value, desc }) => (
                            <TabPanel key={value} value={value} className='mt-10 rounded-2xl overflow-hidden'>
                                <img src={desc} alt="" className='w-full' />
                            </TabPanel>
                        ))}
                    </TabsBody>
                </Tabs>
            </div>
        </>
    )
}