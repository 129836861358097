import { Link } from 'react-scroll';
import { useEffect, useState } from 'react';
import 'preline/dist/preline'


export default function SecondFull(props) {
  // const [activeLink, setActiveLink] = useState('');

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const sections = document.querySelectorAll('section');
  //     const windowHeight = window.innerHeight;
  //     const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  //     let currentSection = '';

  //     sections.forEach((section) => {
  //       const sectionTop = section.offsetTop;
  //       const sectionBottom = sectionTop + section.offsetHeight;
  //       const offset = -windowHeight / 8;

  //       if (scrollPosition >= sectionTop + offset && scrollPosition < sectionBottom + offset) {
  //         currentSection = section.id;
  //       }
  //     });

  //     setActiveLink(currentSection);
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);


  useEffect(() => {
    const handleScroll = () => {
      const x = document.documentElement.scrollTop;
      // console.log(x);
      // const y = document.getElementById('example');
      const n = document.getElementById('docs-sidebar');
      // const z = document.getElementById('under')
      // console.log(z);

      // console.log(y.offsetTop);
      // y.style.opacity = '0';
      n.style.position = 'sticky';
      n.style.top = '100px';

      // if (x + 100 > y.offsetTop) {
        // y.style.opacity = '1';
        // y.style.transition = 'all .2s linear';


      // }


    //   if (x + 580 > z.offsetTop) {
    //     n.style.display = 'none';
    //   }
    //   else if (x - 600 < z.offsetTop) {
    //     n.style.display = 'block';
    //   }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* <div className={`h-[80vh] px-4`} id={props.idd}>
        <div className={`${props.BG} max-w-screen-xl shadow-xl rounded-xl overflow-hidden h-full mx-auto flex flex-col lg:flex lg:flex-row lg:justify-between`}>
          <div className='w-full lg:w-1/3 p-5'>
            <SecondLeft icon={props.icon} title={props.title} description={props.description} />
          </div>
          <div className='w-full lg:w-2/3 h-full  overflow-hidden flex max-md:flex-wrap'>
            <div className={`${props.ulBG} w-full lg:w-1/4 pt-12`}>
              <ul className='font-medium For__Links space-y-2'>
                <li>
                  <Link
                    to='First-Block'
                    spy={true}
                    smooth={true}
                    offset={-window.innerHeight / 8}
                    duration={500}
                    className={activeLink === 'First-Block' ? 'active' : ''}
                  >
                    First Post
                  </Link>
                </li>
                <li>
                  <Link
                    to='Second-Block'
                    spy={true}
                    smooth={true}
                    offset={-window.innerHeight / 8}
                    duration={500}
                    className={activeLink === 'Second-Block' ? 'active' : ''}
                  >
                    Second Post
                  </Link>
                </li>
                <li>
                  <Link
                    to='Third-Block'
                    spy={true}
                    smooth={true}
                    offset={-window.innerHeight / 8}
                    duration={500}
                    className={activeLink === 'Third-Block' ? 'active' : ''}
                  >
                    Third Post
                  </Link>
                </li>
                <li>
                  <Link
                    to='Fourth-Block'
                    spy={true}
                    smooth={true}
                    offset={-window.innerHeight / 8}
                    duration={500}
                    className={activeLink === 'Fourth-Block' ? 'active' : ''}
                  >
                    Fourth Post
                  </Link>
                </li>
              </ul>
            </div>
            <div className='w-3/4 p-5'>
              <SecondRight iconRight={props.iconRight} titleRight={props.titleRight} descriptionRight={props.descriptionRight} />
            </div>
          </div>
        </div>
      </div> */}

      <div className="max-w-screen-xl lg:flex relative mx-auto">
        <div className="sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden">
          <div className="max-w-3xl mx-auto py-2">
            <button type="button" className="flex justify-between gap-x-2 items-center w-full text-gray-500 hover:text-gray-600" data-hs-overlay="#docs-sidebar" aria-controls="docs-sidebar" aria-label="Toggle navigation">
              <span className="text-sm">Toggle Navigation</span>
              <svg className="w-5 h-5" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>
            </button>
          </div>
        </div>
        <div id="docs-sidebar" className=" hs-overlay hs-overlay-open:translate-x-0  -translate-x-full transition-all duration-300 transform hidden  top-0 left-0 bottom-0 z-[60] w-80 bg-white   py-10 px-8 max-sm:pb-3 max-sm:px-4 overflow-y-auto scrollbar-y lg:block lg:translate-x-0 lg:top-0 lg:right-auto lg:bottom-0 lg:left-[max(0px,calc(50%-45rem))] lg:z-10 h-fit">
          <button type="button" className="ml-auto flex justify-end lg:hidden text-gray-500 hover:text-gray-600" data-hs-overlay="#docs-sidebar" aria-controls="docs-sidebar" aria-label="Toggle navigation">
            <span className="sr-only">Toggle Navigation</span>
            <svg className="w-5 h-5" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
          <nav id="sidebar-nav" className="relative space-y-8 max-sm:space-y-0 ">

            <div id='example'>
              <ul className="ml-0.5 space-y-5 max-sm:space-y-0 secondul " data-hs-scrollspy="#scrollspy">
                <li><Link activeClass="active2" spy={true} smooth={true} offset={-300} duration={500} className="porc" to="first">First</Link></li>
                <li><Link activeClass="active2" spy={true} smooth={true} offset={-300} duration={500} className="porc" to="second">Second</Link></li>
                <li><Link activeClass="active2" spy={true} smooth={true} offset={-300} duration={500} className="porc" to="third">Third</Link></li>
                <li><Link activeClass="active2" spy={true} smooth={true} offset={-300} duration={500} className="porc" to="fourth">Fourth</Link></li>
                <li><Link activeClass="active2" spy={true} smooth={true} offset={-300} duration={500} className="porc" to="fifth">Fifth</Link></li>
                <li><Link activeClass="active2" spy={true} smooth={true} offset={-300} duration={500} className="porc" to="sixth">Sixth</Link></li>
              </ul>

            </div>
          </nav>
        </div>
        <div className="w-full   px-4 sm:px-6 md:px-8  ">
          <div className="max-w-3xl mx-auto xl:max-w-none py-10 xl:ml-0  ">

            <div className="">
              <div id="scrollspy" className="space-y-5   ">
                <div id="first" className=" scroll-mt-24 min-h-[25rem] shadow-xl p-10">
                  <h2 className="text-lg font-semibold text-gray-800 ">First section</h2>
                </div>

                <div id="second" className="scroll-mt-24 min-h-[25rem] shadow-xl p-10">
                  <h2 className="text-lg font-semibold text-gray-800 ">Second section</h2>
                </div>

                <div id="third" className="scroll-mt-24 min-h-[25rem] shadow-xl p-10">
                  <h2 className="text-lg font-semibold text-gray-800 ">Third section</h2>
                </div>

                <div id="fourth" className="scroll-mt-24 min-h-[25rem] shadow-xl p-10">
                  <h2 className="text-lg font-semibold text-gray-800 ">Fourth section</h2>
                </div>

                <div id="fifth" className="scroll-mt-24 min-h-[25rem] shadow-xl p-10" >
                  <h2 className="text-lg font-semibold text-gray-800 ">Fifth section
                  </h2>
                </div>

                <div id="sixth" className="scroll-mt-24 min-h-[25rem] shadow-xl p-10">
                  <h2 className="text-lg font-semibold text-gray-800 ">Sixth section</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='under' className='mt-36'>

      </div>
    </>
  );
}
