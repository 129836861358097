import React from "react";
import Info from "./PropsComponents/info";
import nkar from "../assets/img/contactbg.jpg"

export default function Features() {
    const information = [
        { img: nkar, title: 'Instantaneous Results. No Long Waiting Time for Searching.', desc: "White Diamond Pro features an advanced Inventory Search that reacts instantaneously to any searching, filtering, and sorting enabling you to rapidly look up diamond and jewelry items by any search criteria.", i: 1 },
        { img: nkar, title: 'Complete Lot Information. Quickly and Easily Locate an Item.', desc: "The search is specifically designed to provide you with all the necessary information about the item and its status. With one click of a button you can locate the item to see if the item is on hand or on memo; you can quickly and easily see who has it and since when.", i: 2 },
        { img: nkar, title: 'Create a Special Inventory List for Customers. Email, Print, Fax.', desc: "With White Diamond Pro you can create your own list of inventory items and email, print, fax to your customers. White Diamond Pro delivers the powerful inventory search you've always dreamed of.", i: 3 },

    ]

 


    return (

        <>
            <div className="py-20 space-y-10 px-5">
                {
                    information && information.map((item, index) => {
                        if(index % 2 == 0){
                            return  <Info class={'lg:flex lg:flex-row  rounded-2xl overflow-hidden'} img={item.img} title={item.title} desc={item.desc} i={index.i} />
                        }else{
                            return  <Info class={'lg:flex lg:flex-row-reverse  rounded-2xl overflow-hidden'} img={item.img} title={item.title} desc={item.desc} i={index.i} />

                        }
                    })
                }
            </div>
        </>
    )
}