import React from "react"; 
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'; 
import 'react-vertical-timeline-component/style.min.css'; 
import { BsPersonWorkspace } from "react-icons/bs"; 
import { FaSchool } from "react-icons/fa"; 
import { AiFillStar, AiFillChrome, AiFillInstagram, AiFillSlackCircle } from "react-icons/ai"; 
 
export default function HomeVertical() { 
    return ( 
        <> 
            <div className="py-10 sm:py-20 bg-slate-100"> 
 
                <VerticalTimeline> 
                    <VerticalTimelineElement 
                        className="vertical-timeline-element--work" 
                        contentStyle={{ background: '#CCD0D3', color: '#333333', borderRadius: '1rem' }} 
                        date="2011 - present" 
                        iconStyle={{background:"white",  boxShadow:'-1px 8px 34px 4px rgba(0,0,0,0.56)', color: '#333333' }} 
                        icon={<AiFillChrome />} 
                    > 
                        <h3 className="vertical-timeline-element-title font-bold text-xl">Instantaneous Results. No Long Waiting Time for Searching.  </h3> 
                        <h4 className="vertical-timeline-element-subtitle text-lg font-semibold">Miami, FL</h4> 
                        <p className="text-slate-600 text-base">  
                        White Diamond Pro features an advanced Inventory Search that reacts instantaneously to any searching, filtering, and sorting enabling you to rapidly look up diamond and jewelry items by any search criteria.
                        </p> 
                    </VerticalTimelineElement> 
                    <VerticalTimelineElement 
                        className="vertical-timeline-element--work" 
                        contentStyle={{ background: '#CCD0D3', color: '#214460', borderRadius: '1rem' }} 
                        date="2010 - 2011" 
                        iconStyle={{ background: 'white', boxShadow:'-1px 8px 34px 4px rgba(0,0,0,0.56)', color: '#214460' }} 
                        icon={<AiFillInstagram />} 
                    > 
                        <h3 className="vertical-timeline-element-title  font-bold text-xl">Complete Lot Information. Quickly and Easily Locate an Item.</h3> 
                        <h4 className="vertical-timeline-element-subtitle text-lg font-semibold">San Francisco, CA</h4> 
                        <p className="text-slate-600 text-base"> 
                            The search is specifically designed to provide you with all the necessary information about the item and its status. With one click of a button you can locate the item to see if the item is on hand or on memo; you can quickly and easily see who has it and since when.
                        </p> 
                    </VerticalTimelineElement> 
                    <VerticalTimelineElement 
                        className="vertical-timeline-element--work" 
                        contentStyle={{ background: '#CCD0D3', color: '#333333', borderRadius: '1rem' }} 
                        date="2008 - 2010" 
                        iconStyle={{ background: 'white', boxShadow:'-1px 8px 34px 4px rgba(0,0,0,0.56)', color: '#333333' }} 
                        icon={<AiFillSlackCircle />} 
                    > 
                        <h3 className="vertical-timeline-element-title  font-bold text-xl" >Create a Special Inventory List for Customers. Email, Print, Fax.</h3> 
                        <h4 className="vertical-timeline-element-subtitle text-lg font-semibold">Los Angeles, CA</h4> 
                        <p className="text-slate-600 text-base"> 
                        With White Diamond Pro you can create your own list of inventory items and email, print, fax to your customers. White Diamond Pro delivers the powerful inventory search you've always dreamed of.
                        </p> 
                    </VerticalTimelineElement> 
                    <VerticalTimelineElement 
                        className="vertical-timeline-element--work" 
                        contentStyle={{ background: '#CCD0D3', color: '#214460', borderRadius: '1rem' }} 
                        date="2006 - 2008" 
                        iconStyle={{ background: 'white', boxShadow:'-1px 8px 34px 4px rgba(0,0,0,0.56)', color: '#214460' }} 
                        icon={<BsPersonWorkspace />} 
                    > 
                        <h3 className="vertical-timeline-element-title  font-bold text-xl">Get Organized. Diamond Inventory Card.</h3> 
                        <h4 className="vertical-timeline-element-subtitle text-lg font-semibold">San Francisco, CA</h4> 
                        <p className="text-slate-600 text-base"> 
                        Avoid the hassle and save the time of scrambling through pages of messy hand-written notes on your inventory item. White Diamond Pro organizes all your information in one easy to use form.    
                        </p> 
                    </VerticalTimelineElement> 
                    <VerticalTimelineElement 
                        className="vertical-timeline-element--education" 
                        contentStyle={{ background: '#CCD0D3', color: '#333333', borderRadius: '1rem' }} 
                        date="April 2013" 
                        iconStyle={{ background: 'black', boxShadow:'-1px 8px 34px 4px rgba(0,0,0,0.56)', color: '#fff' }} 
                        icon={<FaSchool />} 
                    > 
                        <h3 className="vertical-timeline-element-title  font-bold text-xl">Intelligent Design. Jewelry Inventory Card.</h3> 
                        <h4 className="vertical-timeline-element-subtitle text-lg font-semibold">Online Course</h4> 
                        <p className="text-slate-600 text-base"> 
                        White Diamond Pro's diamond and jewelry inventory cards arrange and store all your information in a neat and efficient format. Due to their unique and intelligent designs, finding the information you want on these screens is quick and easy
                        </p> 
                    </VerticalTimelineElement> 
                    <VerticalTimelineElement 
                        className="vertical-timeline-element--education" 
                        contentStyle={{ background: '#CCD0D3', color: '#214460', borderRadius: '1rem' }} 
                        date="November 2012" 
                        iconStyle={{ background: 'white', boxShadow:'-1px 8px 34px 4px rgba(0,0,0,0.56)', color: 'black' }} 
                        icon={<FaSchool />} 
                    > 
                        <h3 className="vertical-timeline-element-title  font-bold text-xl">All Transactions and History. Memo, Sold, Return, Purchase, Jewelry.</h3> 
                        <h4 className="vertical-timeline-element-subtitle text-lg font-semibold">Certification</h4> 
                        <p className="text-slate-600 text-base"> 
                        With White Diamond Pro you will never again lose track of and control over your inventory items. White Diamond Pro tracks all the transactions performed on the item and also records the complete history of all changes. Knowing your inventory ensures the safety and security of your business. With White Diamond Pro you will never lose a step.
                        </p> 
                    </VerticalTimelineElement> 
                    <VerticalTimelineElement 
                        className="vertical-timeline-element--education" 
                        date="2002 - 2006" 
                        contentStyle={{ background: '#CCD0D3', color: '#333333', borderRadius: '1rem' }} 
                        iconStyle={{ background: 'black', boxShadow:'-1px 8px 34px 4px rgba(0,0,0,0.56)', color: '#fff' }} 
                        icon={<FaSchool />} 
                    > 
                        <h3 className="vertical-timeline-element-title  font-bold text-xl">Instantaneous Search for Potential Matching Stones.</h3> 
                        <h4 className="vertical-timeline-element-subtitle text-lg font-semibold">Bachelor Degree</h4> 
                        <p className="text-slate-600 text-base"> 
                        Within seconds search your entire inventory for potential matching stones, all while on the phone with your customer. The clever color coding scheme clearly identifies all the potential matching stones. White Diamond Pro delivers all the necessary tools to help your business succeed.
                        </p> 
                    </VerticalTimelineElement> 
                    <VerticalTimelineElement 
                        iconStyle={{ background: 'white', boxShadow:'-1px 8px 34px 4px rgba(0,0,0,0.56)', color: 'black' }} 
                        icon={<AiFillStar />} 
                    /> 
                </VerticalTimeline> 
            </div> 
        </> 
    ) 
}