import { useState, useEffect } from "react";
import {
    Navbar,
    MobileNav,
    Typography,
    Button,
    IconButton,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";



export default function Header() {
    
    const [openNav, setOpenNav] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", () => window.innerWidth >= 1000 && setOpenNav(false));
    }, []);

    const navList1 = (
        <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <Link  className="after:content-[''] after:absolute after:bottom-0 after:left-0 after:border-b after:h-0 after:border-main-text after:w-0 hover:after:w-[100%] after:duration-300  duration-700   box-border py-2 px-4 rounded-md text-main-text text-xl relative  cursor-pointer" to="/" duration={500}>Home</Link>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <Link  className="after:content-[''] after:absolute after:bottom-0 after:left-0 after:border-b after:h-0 after:border-main-text after:w-0 hover:after:w-[100%]  after:duration-300 duration-700  text-main-text relative   box-border py-2 px-4 rounded-md   text-xl  cursor-pointer" to="About" duration={500}>About</Link>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <Link  className="after:content-[''] after:absolute after:bottom-0 after:left-0 after:border-b after:h-0 after:border-main-text after:w-0 hover:after:w-[100%] after:duration-300  duration-700  relative text-main-text   box-border py-2 px-4 rounded-md text-xl  cursor-pointer" to="Features" duration={500}>Features</Link>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <Link  className="after:content-[''] after:absolute after:bottom-0 after:left-0 after:border-b after:h-0 after:border-main-text after:w-0 hover:after:w-[100%] after:duration-300 relative  duration-700   text-main-text  box-border py-2 px-4 rounded-md text-xl  cursor-pointer" to="Contact" duration={500}>Contact</Link>
            </Typography>
        </ul>
    );

    const navList2 = (
        <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 justify-center items-center">
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal "
            >
                <Link onClick={() => { setOpenNav(!openNav) }} className="after:content-[''] after:absolute after:bottom-0 after:left-[50%] after:border-b after:h-0 after:border-main-text after:w-0 hover:after:w-[200%] hover:after:left-[-50%] after:duration-300 relative w-full box-border py-2 px-6 rounded-md text-main-text text-xl duration-300 cursor-pointer" to="/" duration={500}>Home</Link>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <Link onClick={() => { setOpenNav(!openNav) }} className="after:content-[''] after:absolute after:bottom-0 after:left-[50%] after:border-b after:h-0 after:border-main-text after:w-0 hover:after:w-[200%] hover:after:left-[-50%] after:duration-300 relative w-full box-border py-2 px-6 text-main-text text-xl  duration-300 cursor-pointer" to="About" duration={500}>About</Link>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <Link onClick={() => { setOpenNav(!openNav) }} className="after:content-[''] after:absolute after:bottom-0 after:left-[50%] after:border-b after:h-0 after:border-main-text after:w-0 hover:after:w-[200%] hover:after:left-[-50%] after:duration-300 relative w-full box-border py-2 px-6 text-main-text text-xl duration-300 cursor-pointer" to="Features" duration={500}>Features</Link>
            </Typography>
            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-normal"
            >
                <Link onClick={() => { setOpenNav(!openNav) }} className="after:content-[''] after:absolute after:bottom-0 after:left-[50%] after:border-b after:h-0 after:border-main-text after:w-0 hover:after:w-[200%] hover:after:left-[-50%] after:duration-300 relative w-full box-border py-2 px-6 text-main-text text-xl duration-300 cursor-pointer" to="Contact" duration={500}>Contact</Link>
            </Typography>
        </ul>
    );

    return (
        <>
            <div className="bg-[#fff] fixed top-0 w-full z-[999999] " >
                
                <Navbar className="mx-auto bg-[#fff] border-none  max-w-screen-xl py-2 px-4 lg:px-8 lg:py-4">
                    <div className="container mx-auto flex items-center justify-between ">
                        <Typography
                            as="a"
                            href="#"
                            className="mr-4 cursor-pointer py-1.5 font-medium"
                        >
                          <Link to="/" className="after:content-[''] after:absolute after:bottom-0 after:left-0 after:border-b after:h-0 after:border-main-text after:w-0 hover:after:w-[100%] after:duration-300 relative duration-700  cursor-pointer  box-border py-2 px-4 rounded-md text-main-text  text-2xl uppercase font-bold" offset={-100} duration={500}>
                                Your Logo
                            </Link>
                        </Typography>
                        <div className="hidden lg:block">{navList1}</div>
                        
                        <IconButton
                            variant="text"
                            className="ml-auto h-6 w-6 text-main-text  AnkaP relative duration-500 focus:bg-transparent active:bg-transparent lg:hidden"
                            ripple={false}
                            onClick={() => setOpenNav(!openNav)}
                        >
                            {openNav ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    className="h-6 w-6"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6" 
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </IconButton>
                    </div>
                    <MobileNav open={openNav}>
                        <div className="container mx-auto">
                            {navList2}
                            
                        </div>
                    </MobileNav>
                </Navbar>
                <div className="w-[97%] mx-auto border-b border-black"></div>
            </div>
            
        </>
    );
}