import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Header from './Pages/Layouts/header';
import Home from './Pages/home';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "./Pages/About";
import Features from "./Pages/features";
import Contact from "./Pages/contact";
import Footer from "./Pages/Layouts/footer";
import ScrollToTop from "./Pages/Components/ScrollToTop";
function App() {
  return (
    <>
      <ScrollToTop />
      <Header />
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Features" element={<Features />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>

      <Footer/>
    </>
  );
}

export default App;
