import React from "react";
import VideoBanner from "./Components/Home/videoBanner";
import PartnerSlider from "./Components/Home/partnerSlider";
import HomeTabs from "./Components/Home/homeTabs";
import SecondFull from "./Components/Home/second";

export default function Home(){
    return(
        <div className="space-y-10">
            <VideoBanner/>
            <SecondFull/>
            <PartnerSlider/>
            <HomeTabs />

        </div>
    )
}
