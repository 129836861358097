import React from "react";
import logo from "../assets/img/footer-logo.png";
import emailjs from "@emailjs/browser";
import { FiMapPin } from "react-icons/fi";
import { BsFillTelephoneFill,} from "react-icons/bs";
import { MdEmail } from "react-icons/md";


export default function Contact() {

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_cchzbwp', 'template_yi2xw4o', e.target, 'oAcw5MZxL1LnpjslT')
            .then((result) => {
                console.log(result);
                e.target.reset();
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <>
            <div className=" py-24 md:py-36 px-5">
                <div className="rounded-3xl shadow-all  bg-[#fff] max-xl:px-6  max-w-screen-xl max-md:p-4 mx-auto flex flex-col justify-center space-y-10  items-center  lg:justify-around ">
                    <div className=" w-full">
                    <div className="flex flex-col py-5  "> 
                        <ul className=" flex flex-col max-md:space-y-6 md:flex md:flex-row md:justify-center md:space-x-12 "> 
                            <li className="space-y-2 flex flex-col justify-center items-center"> <span className="text-main-blue text-3xl lg:text-4xl"><FiMapPin /></span> <span className="font-bold text-xl lg:text-2xl text-main-text"> <a href="https://goo.gl/maps/hx4sGKqUGfyKMt8CA" target="_blank">Put Your Address</a> 
                            </span> 
                            </li> 
 
                            <li className="space-y-2  flex flex-col max-md:justify-center items-center"> <span className="text-main-blue text-4xl"><BsFillTelephoneFill /></span> <span className="font-bold text-xl lg:text-2xl text-main-text"> 
                                <a href="tel:1664243284324">+1664243284324</a></span> 
                            </li> 
                             
                            <li className="space-y-2 flex flex-col max-md:justify-center items-center"> <span className="text-main-blue text-4xl"><MdEmail /></span> <span className="font-bold text-xl lg:text-2xl text-main-text"> <a href="https://www.gmail.com" target="_blank">Example@gmail.com</a> </span></li> 
                        </ul> 
                    </div> 
                    </div>
                    <div className=" max-w-screen-md  ">
                        <div className="   navbar p-5">
                            <form
                                action=""
                                className="  md:flex md:flex-wrap md:justify-between"
                                onSubmit={sendEmail}
                            >

                                <div className="mt-5 relative h-11 w-full md:w-[48%] min-w-[200px]">
                                    <input
                                        className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-s-main-text focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                                        placeholder=" " type="text"
                                    />
                                    <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-main-text after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-main-text text-main-text peer-focus:after:scale-x-100 peer-focus:after:border-main-text peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Name
                                    </label>
                                </div>
                                <div className="mt-5 relative h-11 w-full md:w-[48%] min-w-[200px]">
                                    <input
                                        className="peer  h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-main-text focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                                        placeholder=" " type="text"
                                    />
                                    <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-main-text after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-main-text text-main-text peer-focus:after:scale-x-100 peer-focus:after:border-main-text peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Last Name
                                    </label>
                                </div>
                                <div className="mt-5 relative h-11 w-full md:w-[48%] min-w-[200px]">
                                    <input
                                        className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-main-text focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                                        placeholder=" " type="text"
                                    />
                                    <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-main-text after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-main-text text-main-text peer-focus:after:scale-x-100 peer-focus:after:border-main-text peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Subject
                                    </label>
                                </div>
                                <div className="mt-5 relative h-11 w-full md:w-[48%] min-w-[200px]">
                                    <input
                                        className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-main-text focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" type="email"
                                        placeholder=" "
                                    />
                                    <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-main-text after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-main-text text-main-text peer-focus:after:scale-x-100 peer-focus:after:border-main-text peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                       Email
                                    </label>
                                </div>

                                <div className="mt-10  relative w-full ">
                                <textarea name="comment"  id="comment" className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-main-text focus:border-b-0 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" cols="30" rows="5"></textarea>
                                    <label className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-main-text after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-main-text text-main-text peer-focus:after:scale-x-100 peer-focus:after:border-main-text peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Questions / Comments
                                    </label>
                                </div>

                                <button className="hover:animate-pulse  lg:w-fit  mx-auto mt-5 uppercase duration-1000  text-main-white bg-main-text  px-24 rounded-full font-bold text-xl py-5" type="submit"> Send </button>
                            </form>


                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}