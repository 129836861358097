import React from "react";
import FooterLogo from "../../assets/img/bluelogo.png";
import { MdEmail } from "react-icons/md";
import { BsFillTelephoneFill, BsInstagram, BsYoutube } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import { BsLinkedin } from "react-icons/bs";
import { GrFacebook } from "react-icons/gr";
import { BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function Footer() {

    return (
        <>
            <div className="bg-[#fff] pb-5">
                <div className="w-[97%] mx-auto border-b border-main-blue"></div>
                <div className=" text-main-blue max-md:px-8 max-md:flex-col max-md:justufiy-center space-y-8 md:flex md:justify-around items-center md:max-w-screen-xl mx-auto">

                    <div className="flex-col mt-10  space-y-5 justify-center items-center">
                        <div className="max-md:flex max-md:justify-center hover:cursor-pointer">
                            <Link activeclass="" to="/" spy={false} smooth={true} offset={100} duration={500}>
                                <img src={FooterLogo} alt="" />
                            </Link>
                        </div>


                        <div className="">
                            <ul className="flex justify-center space-x-5 items-center text-xl ">
                                <li className="hover:animate-service p-3 text-base hover:shadow-xl hover:text-blue-500 duration-500 hover:cursor-pointer border rounded-full border-black"> <a href="https://www.linkedin.com/" target="_blank"> <BsLinkedin /> </a> </li>
                                <li className="hover:animate-service p-3 text-base  hover:text-blue-600 duration-500 hover:cursor-pointer border rounded-full border-black"> <a href="https://www.facebook.com/" target="_blank"><GrFacebook /> </a>  </li>
                                <li className="hover:animate-service p-3 text-base  hover:text-blue-400 duration-500 hover:cursor-pointer border rounded-full border-black"> <a href="https://www.twitter.com/" target="_blank"><BsTwitter /></a></li>
                                <li className="hover:animate-service p-3 text-base  hover:text-orange-700 duration-500 hover:cursor-pointer border rounded-full border-black"> <a href="https://www.instagram.com/" target="_blank"><BsInstagram /></a></li>
                                <li className="hover:animate-service p-3 text-base  hover:text-red-500 duration-500 hover:cursor-pointer border rounded-full border-black" > <a href="https://www.youtube.com/" target="_blank"><BsYoutube /></a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="flex justify-around w-[30%] max-sm:flex max-sm:w-full max-sm:justify-around max-md:w-[50%] max-md:mx-auto duration-300">

                        <div className="flex flex-col">
                            <ul className="space-y-2">
                                <li className="flex max-md:justify-center">
                                    <Link activeclass="" to="/" spy={false} smooth={true} offset={100} duration={500}>
                                        Home
                                    </Link>
                                </li>

                                <li className="flex max-md:justify-center">
                                    <Link activeclass="" to="/about" spy={false} smooth={true} offset={100} duration={500}>
                                        About
                                    </Link>
                                </li>

                                <li className="flex max-md:justify-center">
                                    <Link activeclass="" to="/features" spy={false} smooth={true} offset={100} duration={500}>
                                        Features
                                    </Link>
                                </li>
                                <li className="flex max-md:justify-center">
                                    <Link activeclass="" to="/contact" spy={false} smooth={true} offset={100} duration={500}>
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className="flex flex-col">
                            <ul className="space-y-2">
                                <li className="flex max-md:justify-center">
                                    <Link activeclass="" to="/features" spy={false} smooth={true} offset={100} duration={500}>
                                        Features 1
                                    </Link>
                                </li>
                                <li className="flex max-md:justify-center">
                                    <Link activeclass="" to="/features" spy={false} smooth={true} offset={100} duration={500}>
                                        Features 2
                                    </Link>
                                </li>
                                <li className="flex max-md:justify-center">
                                    <Link activeclass="" to="/features" spy={false} smooth={true} offset={100} duration={500}>
                                        Features 3
                                    </Link>
                                </li>
                                <li className="flex max-md:justify-center">
                                    <Link activeclass="" to="/features" spy={false} smooth={true} offset={100} duration={500}>
                                        Features 4
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>


                    <div className="flex flex-col justify-between">
                        <ul className="space-y-5 ">
                            <li className="space-x-5 flex max-md:justify-center items-center"> <FiMapPin /> <span> <a href="https://goo.gl/maps/hx4sGKqUGfyKMt8CA" target="_blank">Put Your Address</a>
                            </span>
                            </li>

                            <li className="space-x-5 flex max-md:justify-center items-center"> <BsFillTelephoneFill /> <span>
                                <a href="tel:1664243284324">+1664243284324</a></span>
                            </li>

                            <li className="space-x-5 flex max-md:justify-center items-center"> <MdEmail /> <span> <a href="https://www.gmail.com" target="_blank">Example@gmail.com</a> </span></li>
                        </ul>
                    </div>


                </div>

            </div>

        </>
    )
}