import React, { Component } from 'react'
import Slider from 'react-slick'
import BlockTitles from '../../PropsComponents/blocktitles'
import SliderProps from '../../PropsComponents/SliderProps'

export default function PartnerSlider () {
  const settings = {
    
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      }
    ]
  }

  const SliderData = [
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/502ApiNcRHgIwrDU8XRYTQ/c70d8752079a40241d08ec85dac5f93d/figma-logo.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/6S40IUVrdki2SlPXQMHKKr/e02c776d183f70c84fa53e077a0f7b1a/pixar.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/5roxdeTF3klGXx5VIEfRAJ/19335a60a4979527e2a34b432b036a18/isntacart_logo.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/1jlyWNfa8mnYxJtmzu5lpV/4072e10330206f9057fe77ab19ce1bca/doordash.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/2VtWKmfmToo8n3X7BY0WwA/ccdcd719ee3bc3fa76148eae11d97c51/nike.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/6dIueLe6mDK6HhNObFq2On/a6cd5c6aa443fe0fc69386522acf7b70/amazon.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/6WgTu82608DIXfxu9KliYL/326d36bbde762fd9c1800872db81244c/pinterest.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/LnKYnStAE4vKs51ejLRj2/69cb3e0382538d4c925208ea24f253f5/General_Electric_logo.svg__1_.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/3o80U1VqLr5X1nE4kWlZM5/7d1493bad9ba1bd0adf0cf21b6751dd2/uber.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/2owwRqFrnSdM98Js9Itzih/15cca0ca61fbbaadfeef6b70108ffb42/plaid.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/18DMuU2Os0OAYiJxqU3aE7/3f2d9ce2c5761513c4f34f41cf85b028/Screen_Shot_2022-09-27_at_11.11_3.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/346udUCJm76OzP8M0yI78w/81099c4f771df9286782581fe18d0efc/snowflake.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/xDZNk5XtOxMEiqsiJ6paq/c9958ceead0fb85872449d26186d62b1/headspace__1_.png',
      alt: 'Brand Image'
    },
    {
      image:
        'https://images.ctfassets.net/spoqsaf9291f/4r1tX1hoz2RZigBJYbFbBJ/37e1dc9aa41342497d3e35a31649b2ae/angel-list.png',
      alt: 'Brand Image'
    }
  ]
  console.log(SliderData.length)
  return (
    <>
      <div className='w-full bg-[#fff] shadow-2xl max-sm:py-3'>
        <div className='max-w-screen-xl mx-auto Block__Padding'>
          <BlockTitles gray={'Third Block '} blue={'Title.'} />
          <Slider {...settings}>
            {SliderData.map((item, index) => {
              return (
                <SliderProps    image={item.image} alt={item.alt} key={index} />
              )
            })}
          </Slider>
        </div>
      </div>
    </>
  )
}
